export default {
  tracked_time: {
    edit: {
      title: 'Redigera rapporterad tid',
      add: 'Lägg till rapporterad tid',
    },
    attributes: {
      name: 'Namn',
      type: 'Typ',
      project: 'Projekt',
      client: 'Kund',
      scheduled_time: 'Planerad tid',
      estimated_time: 'Estimerad tid',
      reported_time: 'Rapporterad tid',
      done: 'Klar',
      task_is_done: 'Uppgift är klar',
      week: 'Vecka',
      time: 'Tid',
      note: 'Notering',
      date: 'Datum',
    },
    tooltip: {
      edit: 'Redigera rapporterad tid',
    },
    empty: 'Ingen rapporterad tid',
    confirm: {
      destroy: {
        title: 'Radera rapporterad tid',
        text: 'Är du säker att du vill radera rapporterad tid?',
      },
    },
  },
  type: {
    project_task: 'Projektuppgift',
    absence: 'Frånvaro',
    activity: 'Aktivitet',
  },
  split: {
    title_project_task: 'Splitta uppgift',
    title_activity: 'Splitta aktivitet',
  },
  confirm: {
    destroy: {
      title: 'Radera event',
      text: 'Är du säker att du vill radera event?',
      activity: {
        confirm: 'Radera denna aktivititet',
        confirm_with_future_events: 'Radera denna och alla framtida aktivititer',
      },
      project_task: {
        confirm: 'Radera denna event',
        confirm_with_future_events: 'Radera denna och alla framtida event',
      },
    },
    mark_week_as_done: {
      title: 'Klarmarkera vecka',
      text: 'Detta sätter alla uppgifter som Klara. Om det finns överskjutande tid på uppgift flyttas den till nästa vecka. Är du säker?',
    },
  },
  event_has_tracked_time: 'Detta event har registrerad tid',
  event_is_done: 'Detta event är klart',
  event_is_invoiced: 'Detta event är fakturerat',
  empty: 'Inga event',
  timeline: {
    client: {
      responsible: 'Kundansvarig',
      services: 'Tjänster',
      financial_year: 'Senaste räkenskapsår',
      other_info: 'Övrig info',
    },
    project: {
      responsible: 'Projekansvarig',
      other_team_members: 'Övriga team-medlemmar',
      financial_year: 'Projektets räkenskapsår',
      dates: 'Projektets leveransdatum / Slutdatum',
    },
    task: {
      note: 'Uppgiftsnotering',
      deadline: 'Deadline',
    },
  },
};
