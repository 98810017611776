export default {
  index: {
    title: 'My weekly planning',
    filters: 'Weekly overview filters',
    create: 'Add',
    task: 'Task & Notifications',
    week_one_letter: 'W',
    firm_activities: 'Firm activities',
    info: 'Info',
  },
  filters: {
    hide_done: 'Hide done',
    show_all: 'Show all tasks',
    prioritised: 'Prioritized',
    has_deadline: 'Has deadline',
    this_week_or_earlier: 'Deadline this week or earlier',
    search_placeholder: 'Search by customer, project, service, task or team member',
  },
  buttons: {
    load_prev_week: 'Load previous week',
    load_next_week: 'Load next week',
  },
  timesheet: {
    timesheet: 'Timesheet',
    summary: 'Summary',
  },
};
