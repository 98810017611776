<script setup lang="ts">
import { reactive, onMounted, watch, ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import VueSelect from 'vue-select';
import VueDatePicker from '@vuepic/vue-datepicker';
import { storeToRefs } from 'pinia';
import { DateTime } from 'luxon';

import useServices from '@/composables/useServices';
import useLoader from '@/composables/useLoader';
import useTrackChanges from '@/composables/useTrackChanges';
import useProjectStore from '@/store/ProjectStore';
import {
  AppLoader,
  AppBox,
  AppBoxBody,
  AppButton,
  FormLabel,
  FormInput,
  FontIcon,
  FinancialYearModal,
  AppAlert,
} from '@/components';
import api from '@/services/api';
import toast from '@/services/toast';
import useClientStore from '@/store/ClientStore';
import { IProjectRequestServiceStepBody, ProjectStep } from '@/types/Project';
import { IFinancialYearResource } from '@/types/FinancialYear';
import { IUserListResource, IUserPreviewResource } from '@/types/User';
import { IServicePreviewResource, ServiceDefaultDeadline } from '@/types/Service';
import { Tooltip } from 'floating-vue';

const id = inject<number | null>('projectId', null);
const clientUuid = inject<string>('clientUuid', '');
const editMode = inject<boolean>('editMode', false);

const projectStore = useProjectStore();
const { setProject, lockTabs, unlockTabs } = projectStore;
const { project, isActive } = storeToRefs(projectStore);
const { services, getServices } = useServices();
const { client } = storeToRefs(useClientStore());

const emit = defineEmits<{
  (e: 'change-step', step: ProjectStep): void;
}>();

const router = useRouter();
const { t, locale } = useI18n({ useScope: 'global' });
const loader = useLoader({ useProgress: false });
const nextLoader = useLoader({ useProgress: false });
const updateLoader = useLoader({ useProgress: false });

const submitMode = ref<'back' | 'update' | 'next'>('next');

const users = ref<IUserListResource[]>([]);
const financialYears = ref<IFinancialYearResource[]>([]);

const initialFormToCompare = ref('');

const form = reactive<IProjectRequestServiceStepBody>({
  name: null,
  service_uuid: null,
  financial_year_uuid: null,
  user_uuid: null,
  start_date: DateTime.now().toFormat('yyyy-MM-dd'),
  end_date: null,
  deadline_date: null,
  step: ProjectStep.Service,
});

const tracker = useTrackChanges(form);

const financialYearModal = useModal({
  component: FinancialYearModal,
  attrs: {
    clientUuid: client.value.uuid,
    async onCreated(newFinancialYear: IFinancialYearResource) {
      await getFinancialYears();
      form.financial_year_uuid = newFinancialYear.uuid;
      await financialYearModal.close();
    },
    onCancel() {
      financialYearModal.close();
    },
  },
});

async function getFinancialYears() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.financialYears.list(clientUuid!, searchParams);
    financialYears.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function getUsers() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('status', 'active');
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function submit() {
  lockTabs();
  if (submitMode.value === 'next') nextLoader.start();
  else if (submitMode.value === 'update') updateLoader.start();
  try {
    if (id) {
      const response = await api.projects.update(form, clientUuid, id);
      setProject(response.data);
      if (submitMode.value === 'next') {
        emit('change-step', ProjectStep.Price);
      }
      if (submitMode.value === 'update') {
        toast.success(t('common.messages.has_been_updated', { name: t('common.project') }));
      }
    } else {
      const response = await api.projects.store(form, clientUuid);
      await router.push({
        name: 'projects.edit',
        params: { uuid: clientUuid, id: response.data.id },
        query: { step: ProjectStep.Price },
      });
    }
  } catch (error) {
    console.error(error);
  } finally {
    nextLoader.finish();
    updateLoader.finish();
    unlockTabs();
  }
}

function generateProjectName() {
  const names: string[] = [];
  if (form.service_uuid && form.financial_year_uuid) {
    const service = services.value.find(({ uuid }) => uuid === form.service_uuid)!;
    names.push(service.name);
    const financialYear = financialYears.value.find(({ uuid }) => uuid === form.financial_year_uuid)!;
    const startYear = DateTime.fromISO(financialYear.start_date).toFormat('yy');
    const endYear = DateTime.fromISO(financialYear.end_date).toFormat('yy');
    names.push(startYear === endYear ? startYear : `${startYear}/${endYear}`);
  }
  form.name = names.join(' ');
}

function monitorAndSetEndDate() {
  if (form.end_date || form.service_uuid === null) return;
  const service = services.value.find(({ uuid }) => uuid === form.service_uuid)!;
  const financialYear = financialYears.value.find(({ uuid }) => uuid === form.financial_year_uuid);
  switch (service.default_dead_line) {
    case ServiceDefaultDeadline.CALENDAR_QUARTER_END_PLUS_40_DAYS:
      if (form.start_date === null) return;
      form.end_date = DateTime.fromISO(form.start_date).endOf('quarter').plus({ days: 40 }).toFormat('yyyy-MM-dd');
      break;
    case ServiceDefaultDeadline.CALENDAR_YEAR_END_PLUS_4_MONTHS:
      if (form.start_date === null) return;
      form.end_date = DateTime.fromISO(form.start_date).endOf('year').plus({ months: 4 }).toFormat('yyyy-MM-dd');
      break;
    case ServiceDefaultDeadline.CALENDAR_YEAR_END_PLUS_160_DAYS:
      if (form.start_date === null) return;
      form.end_date = DateTime.fromISO(form.start_date).endOf('year').plus({ days: 160 }).toFormat('yyyy-MM-dd');
      break;
    case ServiceDefaultDeadline.MONTH_END_PLUS_26_DAYS:
      if (form.start_date === null) return;
      form.end_date = DateTime.fromISO(form.start_date).endOf('month').plus({ days: 26 }).toFormat('yyyy-MM-dd');
      break;
    case ServiceDefaultDeadline.PROJECT_START_DATE_PLUS_2_MONTHS:
      if (form.start_date === null) return;
      form.end_date = DateTime.fromISO(form.start_date).plus({ months: 2 }).toFormat('yyyy-MM-dd');
      break;
    case ServiceDefaultDeadline.FINANCIAL_YEAR_END_PLUS_1_MONTH:
      if (!financialYear) return;
      form.end_date = DateTime.fromISO(financialYear.end_date).plus({ months: 1 }).toFormat('yyyy-MM-dd');
      break;
    case ServiceDefaultDeadline.FINANCIAL_YEAR_END_PLUS_6_MONTHS:
      if (!financialYear) return;
      form.end_date = DateTime.fromISO(financialYear.end_date).plus({ months: 6 }).toFormat('yyyy-MM-dd');
      break;
    default:
      break;
  }
}

onMounted(async () => {
  lockTabs();
  loader.start();
  await Promise.all([
    getUsers(),
    getFinancialYears(),
    getServices({
      searchParams: {
        without_pagination: 1,
        only_active: Number(!editMode),
      },
    }),
  ]);
  form.user_uuid = client.value.responsible_user.uuid;
  if (editMode) {
    form.name = project.value.name;
    form.service_uuid = project.value.service.uuid;
    form.financial_year_uuid = project.value.financial_year.uuid;
    form.user_uuid = project.value.user.uuid;
    form.start_date = DateTime.fromISO(project.value.start_date).toFormat('yyyy-MM-dd');
    form.end_date = DateTime.fromISO(project.value.end_date).toFormat('yyyy-MM-dd');
    form.deadline_date = project.value.deadline_date
      ? DateTime.fromISO(project.value.deadline_date).toFormat('yyyy-MM-dd')
      : null;
    form.service_uuid = project.value.service.uuid;
  }
  initialFormToCompare.value = JSON.stringify(form);
  loader.finish();
  watch([() => form.financial_year_uuid, () => form.service_uuid], generateProjectName);

  watch([() => form.service_uuid, () => form.start_date, () => form.financial_year_uuid], monitorAndSetEndDate);

  tracker.commit();
  unlockTabs();
});
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <form v-else @submit.prevent="submit">
    <AppBox class="mt-4" shadow>
      <AppBoxBody>
        <div class="row">
          <div class="col-lg-10 col-xl-8">
            <!-- Service -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <FormLabel html-for="service" :required="!form.service_uuid">
                    <RouterLink
                      v-if="form.service_uuid"
                      :to="{ name: 'services.edit', params: { uuid: form.service_uuid } }"
                      target="_blank"
                    >
                      {{ t('project.attributes.service') }}
                    </RouterLink>
                    <span v-else v-text="t('project.attributes.service')" />
                  </FormLabel>
                </div>
                <div class="col-md-8">
                  <VueSelect
                    :clearable="false"
                    v-model="form.service_uuid"
                    :options="services"
                    :reduce="(option: IServicePreviewResource) => option.uuid"
                    label="name"
                    input-id="service"
                    :placeholder="t('common.select')"
                    required
                    :disabled="editMode"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!form.service_uuid"
                        v-bind="attributes as object"
                        v-on="events"
                      />
                    </template>
                  </VueSelect>
                </div>
              </div>
            </div>
            <!-- Financial year -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <FormLabel html-for="financial_year" required>
                    {{ t('project.attributes.financial_year') }}
                  </FormLabel>
                </div>
                <div class="col-md-8 d-flex align-items-center">
                  <div class="flex-grow-1">
                    <VueSelect
                      :clearable="false"
                      :filterable="false"
                      v-model="form.financial_year_uuid"
                      :options="financialYears"
                      :reduce="(option: IFinancialYearResource) => option.uuid"
                      input-id="financial_year"
                      :placeholder="t('common.select')"
                      :get-option-label="
                        (option: IFinancialYearResource) => `${DateTime.fromISO(option.start_date).toFormat('yyyy-MM-dd')} - ${DateTime.fromISO(option.end_date).toFormat('yyyy-MM-dd')}`
                      "
                      required
                    >
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!form.financial_year_uuid"
                          v-bind="attributes as object"
                          v-on="events"
                        />
                      </template>
                    </VueSelect>
                  </div>
                  <AppButton @click.prevent="financialYearModal.open" class="flex-shrink-0 ml-2" light circle>
                    <FontIcon name="plus" />
                  </AppButton>
                </div>
              </div>
            </div>
            <!-- Project start date -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <FormLabel html-for="dp-input-start_date" required>
                    {{ t('project.attributes.start_date') }}
                  </FormLabel>
                </div>
                <div class="col-md-8">
                  <div class="form-wrapper has-icon">
                    <VueDatePicker
                      uid="start_date"
                      :ui="{ input: 'form-control' }"
                      :placeholder="t('common.select')"
                      v-model="form.start_date"
                      model-type="format"
                      format="yyyy-MM-dd"
                      :enable-time-picker="false"
                      :month-change-on-scroll="false"
                      required
                      auto-apply
                      text-input
                      :max-date="form.end_date ?? ''"
                      :locale="locale"
                      :week-num-name="t('common.week_short')"
                      :clearable="false"
                    >
                      <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                    </VueDatePicker>
                  </div>
                </div>
              </div>
            </div>
            <!-- Project end date -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <FormLabel html-for="dp-input-end_date" required>
                    {{ t('project.attributes.end_date') }}
                    <Tooltip class="d-inline-block" placement="right">
                      <FontIcon name="info-circle" />
                      <template #popper>
                        <div style="max-width: 400px">{{ t('project.tooltip.end_date') }}</div>
                      </template>
                    </Tooltip>
                  </FormLabel>
                </div>
                <div class="col-md-8">
                  <div class="form-wrapper has-icon">
                    <VueDatePicker
                      uid="end_date"
                      :ui="{ input: 'form-control' }"
                      :placeholder="t('common.select')"
                      v-model="form.end_date"
                      model-type="format"
                      format="yyyy-MM-dd"
                      :enable-time-picker="false"
                      :month-change-on-scroll="false"
                      text-input
                      :min-date="form.start_date ?? ''"
                      required
                      auto-apply
                      :locale="locale"
                      :week-num-name="t('common.week_short')"
                      :clearable="false"
                    >
                      <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                    </VueDatePicker>
                  </div>
                </div>
              </div>
            </div>
            <!-- Project deadline date -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <FormLabel html-for="dp-input-deadline_date">
                    {{ t('project.attributes.deadline_date') }}
                    <Tooltip class="d-inline-block" placement="right">
                      <FontIcon name="info-circle" />
                      <template #popper>
                        <div style="max-width: 400px">{{ t('project.tooltip.deadline_date') }}</div>
                      </template>
                    </Tooltip>
                  </FormLabel>
                </div>
                <div class="col-md-8">
                  <div class="form-wrapper has-icon">
                    <VueDatePicker
                      uid="deadline_date"
                      :ui="{ input: 'form-control' }"
                      :placeholder="t('common.select')"
                      v-model="form.deadline_date"
                      model-type="format"
                      format="yyyy-MM-dd"
                      :enable-time-picker="false"
                      :month-change-on-scroll="false"
                      text-input
                      :min-date="form.start_date ?? ''"
                      :max-date="form.end_date ?? ''"
                      auto-apply
                      :locale="locale"
                      :week-num-name="t('common.week_short')"
                      clearable
                    >
                      <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                    </VueDatePicker>
                  </div>
                </div>
              </div>
            </div>
            <!-- Project name -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <FormLabel html-for="name" required>{{ t('project.attributes.name') }}</FormLabel>
                </div>
                <div class="col-md-8">
                  <FormInput id="name" v-model="form.name" :placeholder="t('project.placeholders.name')" required />
                </div>
              </div>
            </div>
            <!-- User -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <FormLabel html-for="user" required>{{ t('project.attributes.user') }}</FormLabel>
                </div>
                <div class="col-md-8">
                  <VueSelect
                    :clearable="false"
                    v-model="form.user_uuid"
                    :options="users"
                    :reduce="(option: IUserPreviewResource) => option.uuid"
                    label="name"
                    input-id="user"
                    :placeholder="t('common.select')"
                    required
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!form.user_uuid"
                        v-bind="attributes as object"
                        v-on="events"
                      />
                    </template>
                  </VueSelect>
                </div>
              </div>
            </div>

            <!-- Alerts -->
            <AppAlert
              v-if="isActive && (tracker.isFieldModified(['start_date']) || tracker.isFieldModified(['end_date']))"
              type="warning"
            >
              <h3 v-text="t('project.messages.project_dates_updated.title')" />
              {{ t('project.messages.project_dates_updated.text') }}
            </AppAlert>
          </div>
        </div>
      </AppBoxBody>
    </AppBox>
    <div class="mt-3 d-flex flex-nowrap">
      <AppButton
        v-if="editMode"
        @click="submitMode = 'update'"
        class="ml-auto mr-2"
        color="success"
        :loading="updateLoader.isLoading.value"
        :disabled="nextLoader.isLoading.value"
      >
        {{ t('common.update') }}
      </AppButton>
      <AppButton
        :class="{ 'ml-auto': !editMode }"
        @click="submitMode = 'next'"
        color="secondary"
        :loading="nextLoader.isLoading.value"
        :disabled="updateLoader.isLoading.value"
      >
        {{ t('common.next') }}
      </AppButton>
    </div>
  </form>
</template>
