import route from 'ziggy-js';
import { Options, SearchParamsOption } from 'ky';
import httpClient from '@/services/api/ky';
import {
  ClientType,
  IClientCategory,
  IClientFullResource,
  IClientRequestBody,
  IClientSearchResource,
  IClientBasicInfo,
  IClientStoreRequestBody,
  IClientUpdateRequestBody,
  IClientUpdateInformationRequestBody,
  IClientCreditInfo,
  IClientBillingListResource,
  IClientProcesses,
  IClientTagResource,
  IClientTagRequest,
  IClientItSystemResource,
  IClientItSystemRequest,
  IClientListResource,
  IClientCheckpointListResource,
} from '@/types/Client';
import { IResponse } from '@/types/Response';
import {
  IProjectCheckpointEventRequest,
  IProjectCheckpointEventResource,
  IProjectPreviewResource,
  IProjectTaskListResource,
} from '@/types/Project';
import {
  IEventReportedTimeResource,
  ITimeEntryProjectTaskEventRequest,
  ITimeEntryProjectTaskEventResource,
} from '@/types/Event';
import { IContactFullResource, IContactRequestBody, IContactRole, IContactRoleRequestBody } from '@/types/Contact';

type GetClientMeta = {
  meta: {
    is_user_part_of_team: boolean;
  };
};

export default {
  list(options?: Options): Promise<IResponse<IClientListResource[]>> {
    return httpClient.get(route('clients.index'), options).json();
  },
  get(client: string): Promise<IResponse<IClientFullResource> & GetClientMeta> {
    return httpClient.get(route('clients.get', { client })).json();
  },
  store(body: IClientStoreRequestBody): Promise<IResponse<IClientFullResource>> {
    return httpClient.post(route('clients.store'), { json: body }).json();
  },
  update(
    body: IClientUpdateRequestBody | IClientUpdateInformationRequestBody,
    client: string,
  ): Promise<IResponse<IClientFullResource>> {
    return httpClient.patch(route('clients.update', { client }), { json: body }).json();
  },
  destroy(client: string): Promise<IResponse<never>> {
    return httpClient.delete(route('clients.delete', { client })).json();
  },
  search(search: string, type: ClientType): Promise<IResponse<IClientSearchResource[]>> {
    return httpClient.get(route('client'), { searchParams: { type, search } }).json();
  },
  getClientCreditReportDownloadLink(client: string): Promise<IResponse<{ url: string }>> {
    return httpClient.get(route('clients.client-credit-report-download-link', { client })).json();
  },
  contacts: {
    list(client: string, options: Options = {}): Promise<IResponse<IContactFullResource[]>> {
      return httpClient.get(route('clients.contacts.list', { client }), options).json();
    },
    sync(client: string): Promise<IResponse<IContactFullResource[]>> {
      return httpClient.get(route('clients.contacts.sync', { client }), {}).json();
    },
    store(client: string, body: IContactRequestBody): Promise<IResponse<IContactFullResource>> {
      return httpClient.post(route('clients.contacts.store', { client }), { json: body }).json();
    },
    update(client: string, contact: string, body: IContactRequestBody): Promise<IResponse<IContactFullResource>> {
      return httpClient.patch(route('clients.contacts.update', { client, contact }), { json: body }).json();
    },
    roles: {
      list(searchParams?: SearchParamsOption): Promise<IResponse<IContactRole[]>> {
        return httpClient.get(route('clients.contacts.roles.index'), { searchParams }).json();
      },
      store(body: IContactRoleRequestBody): Promise<IResponse<IContactRole>> {
        return httpClient.post(route('clients.contacts.roles.store'), { json: body }).json();
      },
    },
    delete(client: string, contact: string, options: Options = {}) {
      return httpClient.delete(route('clients.contacts.delete', { client, contact }), options).json<void>();
    },
  },
  projects: {
    list(client: string, options: Options = {}): Promise<IResponse<IProjectPreviewResource[]>> {
      return httpClient.get(route('clients.projects.index', { client }), options).json();
    },
    get(client: string, id: number): Promise<IResponse<IClientFullResource>> {
      return httpClient.get(route('clients.projects.get', { client, id })).json();
    },
    store(body: IClientRequestBody): Promise<IResponse<IClientFullResource>> {
      return httpClient.post(route('clients.projects.store'), { json: body }).json();
    },
    update(body: IClientRequestBody, client: string, id: number): Promise<IResponse<IClientFullResource>> {
      return httpClient.patch(route('clients.projects.update', { client, id }), { json: body }).json();
    },
    destroy(client: string, id: number): Promise<IResponse<never>> {
      return httpClient.delete(route('clients.projects.delete', { client, id })).json();
    },
    tasks: {
      list(client: string, searchParams?: SearchParamsOption): Promise<IResponse<IProjectTaskListResource[]>> {
        return httpClient.get(route('clients.tasks-list', { client }), { searchParams }).json();
      },
      store(client: string, id: number, serviceTask: number, json: ITimeEntryProjectTaskEventRequest) {
        return httpClient
          .post(route('clients.projects.tasks.store', { client, id, serviceTask }), { json })
          .json<IResponse<ITimeEntryProjectTaskEventResource>>();
      },
    },
    checkpoints: {
      events: {
        index(options: Options = {}) {
          return httpClient
            .get(route('clients.projects.checkpoints.events.index'), options)
            .json<IResponse<IClientCheckpointListResource[]>>();
        },
        update(event: number, json: IProjectCheckpointEventRequest, options: Options = {}) {
          return httpClient
            .patch(route('clients.projects.checkpoints.events.update', { event }), { ...options, json })
            .json<IResponse<IProjectCheckpointEventResource>>();
        },
      },
    },
  },
  info: {
    basic(org_number: string, type: ClientType): Promise<IClientBasicInfo> {
      return httpClient
        .get(route('clients.client_info.basic-client-info'), { searchParams: { org_number, type } })
        .json();
    },
    credit(client: string): Promise<IResponse<IClientCreditInfo>> {
      return httpClient.get(route('clients.client_info.credit-client-info', { client }), {}).json();
    },
  },
  processes: {
    get(client: string): Promise<IResponse<IClientProcesses>> {
      return httpClient.get(route('clients.processes.get', { client })).json();
    },
    update(client: string, json: IClientProcesses): Promise<IResponse<IClientProcesses>> {
      return httpClient.put(route('clients.processes.update', { client }), { json }).json();
    },
  },
  events: {
    index(client: string, options?: Options): Promise<IEventReportedTimeResource> {
      return httpClient.get(route('clients.events-list', { client }), options).json();
    },
  },
  categories: {
    list(): Promise<IResponse<IClientCategory[]>> {
      return httpClient.get(route('clients.client-categories')).json();
    },
  },
  billing: {
    list(client: string, searchParams?: SearchParamsOption): Promise<IResponse<IClientBillingListResource>> {
      return httpClient.get(route('clients.billing.index', { client }), { searchParams }).json();
    },
  },
  serviceCompanies: {
    attach(client: string, serviceCompany: string): Promise<never> {
      return httpClient.post(route('clients.service-companies.attach', { client, serviceCompany })).json();
    },
    detach(client: string, serviceCompany: string): Promise<never> {
      return httpClient.post(route('clients.service-companies.detach', { client, serviceCompany })).json();
    },
    contacts: {
      attach(client: string, serviceCompany: string, serviceCompanyContact: string): Promise<never> {
        return httpClient
          .post(route('clients.service-companies.contacts.attach', { client, serviceCompany, serviceCompanyContact }))
          .json();
      },
      detach(client: string, serviceCompany: string, serviceCompanyContact: string): Promise<never> {
        return httpClient
          .post(route('clients.service-companies.contacts.detach', { client, serviceCompany, serviceCompanyContact }))
          .json();
      },
    },
  },
  tags: {
    index(options: Options = {}): Promise<IResponse<IClientTagResource[]>> {
      return httpClient.get(route('clients.tags.index'), options).json();
    },
    store(json: IClientTagRequest, options: Options = {}): Promise<IResponse<IClientTagResource>> {
      return httpClient.post(route('clients.tags.store'), { ...options, json }).json();
    },
    update(tag: number, json: IClientTagRequest, options: Options = {}): Promise<IResponse<IClientTagResource>> {
      return httpClient.patch(route('clients.tags.update', { tag }), { ...options, json }).json();
    },
    delete(tag: number, options: Options = {}): Promise<void> {
      return httpClient.delete(route('clients.tags.delete', { tag }), options).json();
    },
  },
  itSystems: {
    index(options: Options = {}): Promise<IResponse<IClientItSystemResource[]>> {
      return httpClient.get(route('clients.it-systems.index'), options).json();
    },
    store(json: IClientItSystemRequest, options: Options = {}): Promise<IResponse<IClientItSystemResource>> {
      return httpClient.post(route('clients.it-systems.store'), { ...options, json }).json();
    },
    update(
      system: number,
      json: IClientItSystemRequest,
      options: Options = {},
    ): Promise<IResponse<IClientItSystemResource>> {
      return httpClient.patch(route('clients.it-systems.update', { system }), { ...options, json }).json();
    },
    delete(system: number, options: Options = {}): Promise<void> {
      return httpClient.delete(route('clients.it-systems.delete', { system }), options).json();
    },
  },
};
