<script setup lang="ts">
import { computed, reactive, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueDatePicker from '@vuepic/vue-datepicker';

import api from '@/services/api';
import { AppButton, FormLabel } from '@/components';
import useLoader from '@/composables/useLoader';
import { IFinancialYearResource, IFinancialYearRequestBody } from '@/types/FinancialYear';
import { DateTime } from 'luxon';

const { t, locale } = useI18n({ useScope: 'global' });
const submitLoader = useLoader({ useProgress: false });

const props = defineProps<{
  clientUuid: string;
}>();

const form = reactive<IFinancialYearRequestBody>({
  start_date: '',
  end_date: '',
});

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'created', newFinancialYear: IFinancialYearResource): void;
}>();

const disabled = computed(() => !form.start_date || !form.end_date);

async function submit() {
  try {
    submitLoader.start();
    const response = await api.financialYears.store(props.clientUuid, form);
    emit('created', response.data);
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}

onMounted(() => {
  form.start_date = DateTime.local(DateTime.now().year, 1, 1).toFormat('yyyy-MM-dd');
  form.end_date = DateTime.local(DateTime.now().year, 12, 31).toFormat('yyyy-MM-dd');
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    content-style="overflow: visible;"
    :click-to-close="false"
    :esc-to-close="true"
  >
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-text="t('financial-year.modal.create.title')" />
      </div>
      <div class="modal-content">
        <!-- Start date -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="dp-input-start_date" required>
                {{ t('financial-year.attributes.start_date') }}
              </FormLabel>
            </div>
            <div class="col-md-9">
              <div class="form-wrapper has-icon">
                <VueDatePicker
                  uid="start_date"
                  :ui="{ input: 'form-control' }"
                  :placeholder="t('common.select')"
                  v-model="form.start_date"
                  model-type="format"
                  auto-apply
                  format="yyyy-MM-dd"
                  :enable-time-picker="false"
                  :month-change-on-scroll="false"
                  required
                  text-input
                  :locale="locale"
                  :week-num-name="t('common.week_short')"
                  :max-date="form.end_date ?? ''"
                  :clearable="false"
                >
                  <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                </VueDatePicker>
              </div>
            </div>
          </div>
        </div>
        <!-- End date -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="dp-input-end_date" required>
                {{ t('financial-year.attributes.end_date') }}
              </FormLabel>
            </div>
            <div class="col-md-9">
              <div class="form-wrapper has-icon">
                <VueDatePicker
                  uid="end_date"
                  :ui="{ input: 'form-control' }"
                  :placeholder="t('common.select')"
                  v-model="form.end_date"
                  model-type="format"
                  auto-apply
                  format="yyyy-MM-dd"
                  :enable-time-picker="false"
                  :month-change-on-scroll="false"
                  required
                  :min-date="form.start_date ?? ''"
                  text-input
                  :locale="locale"
                  :clearable="false"
                >
                  <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                </VueDatePicker>
              </div>
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="emit('cancel')">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton
            class="mt-2 mt-sm-0"
            color="secondary"
            :disabled="disabled"
            :loading="submitLoader.isLoading.value"
          >
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
